import React from "react";

class ReadMore extends React.Component {
    
  componentDidMount() {
    let frontEnd = document.createElement('script');
    frontEnd.src = '/src/js/read-more.js'; 
    frontEnd.id = 'read-more-js';
    document.body.appendChild(frontEnd);
  }

  componentWillUnmount() {
    document.getElementById('read-more-js').remove()
  }

  render() {
    return (
        <div class="immersive-scroll-wrap">


        </div>
       
  );
  }
};

export default ReadMore;