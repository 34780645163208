import React from 'react'
import { LiquidDistortionText } from 'react-text-fun'
import $ from 'jquery'
import Credits from './Credits'
import BackgroundType from './BackgroundType'

const SingleTopPage = ( {topPageData} ) => {
    // console.log('toppage')
    console.log(topPageData)

   
    return (
        <section className="top-of-page-item top-page single-banner mb-5">

            <div className="t-wrap">

                <Credits creditData={topPageData.topBanner} />
               

                <div className="container container-fluid mx-5 mt-0 h-100">
                    <div className="row h-100 align-items-center">

                        <div className="col-lg-11 col-md-11 col-sm-11 col-xs-12 offset-md-1 offset-sm-1 offset-lg-1 mt-0 rellax" data-rellax-speed="4">
                        
                            <h1 tabIndex="3">{topPageData.topBanner.articleTitle.firstRow}</h1>
                            <div className="blotter" tabIndex="3" aria-label={topPageData.topBanner.articleTitle.secondRow}>
                                <h2>{topPageData.topBanner.articleTitle.secondRow}</h2>
                            </div>
                            <div className="blotter-disabled d-none">
                            <h2 tabIndex="3">{topPageData.topBanner.articleTitle.secondRow}</h2>
                            </div>
                            <h3 tabIndex="3">{topPageData.topBanner.articleTitle.thirdRow}</h3>

                            <div className="article-tidbits">
                                <div className="lottie-wrap">

                                    <img src={topPageData.topBanner.svgUpload.remoteFile.publicURL} />


                                </div>        
                                
                                <p tabIndex="3">{topPageData.topBanner.articleSubtitle}</p>
                            </div>
                        
                        </div>

                    
                    </div>
                </div>
            </div>

            <BackgroundType backgroundData={topPageData.topBanner} />

            

        </section>
    )
}

  
export default SingleTopPage;