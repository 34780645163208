import React from "react"
import TransitionLink from 'gatsby-plugin-transition-link'
import Link from 'gatsby-plugin-transition-link'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import FeaturedMedia from './FeaturedMedia'

const ContentTypePagination = ({ previousPage, nextPage, contentType }) => {
  return (
    <nav
      className="next-prev-articles m-0"
      aria-label={contentType}
      role="navigation"
    >

      

        {/* <div class="next-prev-articles">

          <div class="next-prev-page previous-post">
            <div class="img-wrap">
            <div class="mask-wrap">
            <div class="mask">
            </div>
            </div>
            <img width="640" height="149" src="http://kentoliver40.local/wp-content/uploads/2019/02/foldem-1024x239.jpg" class="attachment-large size-large wp-post-image" alt="" srcset="http://kentoliver40.local/wp-content/uploads/2019/02/foldem-1024x239.jpg 1024w, http://kentoliver40.local/wp-content/uploads/2019/02/foldem-300x70.jpg 300w, http://kentoliver40.local/wp-content/uploads/2019/02/foldem-768x180.jpg 768w, http://kentoliver40.local/wp-content/uploads/2019/02/foldem.jpg 1369w" sizes="(max-width: 640px) 100vw, 640px">
            </div>
            <a rel="prev" class="h6" href="http://kentoliver40.local/hello-world/" title="Know when to hold em Know when to fold em">
            <p>Know when to hold em<br> Know when to fold em
            <span>Previous Post</span>
            </p>
            </a>
          </div>

          <div class="next-prev-page next-post">
          <div class="img-wrap">
          <div class="mask-wrap">
          <div class="mask">
          </div>
          </div>
          <img width="640" height="149" src="http://kentoliver40.local/wp-content/uploads/2020/05/granny-1024x239.jpg" class="attachment-large size-large wp-post-image" alt="" srcset="http://kentoliver40.local/wp-content/uploads/2020/05/granny-1024x239.jpg 1024w, http://kentoliver40.local/wp-content/uploads/2020/05/granny-300x70.jpg 300w, http://kentoliver40.local/wp-content/uploads/2020/05/granny-768x180.jpg 768w, http://kentoliver40.local/wp-content/uploads/2020/05/granny.jpg 1369w" sizes="(max-width: 640px) 100vw, 640px">
          </div>
          <a rel="next" class="h6" href="http://kentoliver40.local/the-pros-and-cons-of-designing-while-developing/" title="The pros and cons of  designing while developing">
          <p>The pros and cons of<br>  designing while developing
          <span>Next Post</span>
          </p>
          </a>
          </div>

    </div> */}

     
        {previousPage && (
          <div className="next-prev-page previous-post">
           <AniLink 
           fade 
           to={previousPage.uri}
           duration={0.4}
           className="previous-post"
           >
            <div className="t-wrap">
              <p>{previousPage.ACFPostFields.titleLead}</p>
              <p>{previousPage.ACFPostFields.postSubTitle}</p>
              <h6>Previous Post</h6>
            </div>
            <div className="img-wrap">
              <div className="mask-wrap">
                <div className="mask">
                </div>
              </div>
               <FeaturedMedia image={previousPage.featuredImage} />
            </div>

           </AniLink>
           </div>
        )}

        {nextPage && (
          <div className="next-prev-page next-post">
          <AniLink 
          fade 
          to={nextPage.uri}
          duration={0.4}
          className="next-post"
          >
          <div className="t-wrap">
            <p>{nextPage.ACFPostFields.titleLead}</p>
            <p>{nextPage.ACFPostFields.postSubTitle}</p>
            <h6>Next Post</h6>
          </div>

           <div className="img-wrap">
             <div className="mask-wrap">
               <div className="mask">
               </div>
             </div>
              <FeaturedMedia image={nextPage.featuredImage} />
           </div>
          </AniLink>
          </div>
        )}
     

    </nav>
  )
}

export default ContentTypePagination
