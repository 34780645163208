import React from 'react'
import ReactDOM from 'react-dom'

import { graphql } from 'gatsby'
import Layout from '../../components/Layout'
import Seo from '../../components/Seo'

import ContentTypePagination from '../../components/ContentTypePagination'

import PostMeta from '../../components/PostMeta'
import PostCategories from '../../components/PostCategories'
import FeaturedMedia from '../../components/FeaturedMedia'


import ImmersiveScroll from '../../components/blocks/ImmersiveScroll'
import ReadMore from '../../components/blocks/ReadMore'

import TopPage from '../../components/SingleTopPage'

import BlockBuilder from '../../components/builder/BlockBuilder'

import $ from 'jquery'

export default ({ data }) => {
 
  

    

  const { nextPage, previousPage, page } = data
  const {
    title,
    content,
    blocks,
    featuredImage,
    categories,
    excerpt,
    databaseId,
    author,
    date,
    ACFPostFields
  } = page

  console.log( blocks )

  const allBlocks = blocks

  // const allCustomBlocks = allBlocks.map((blox, c) => <li key={c}>{blox}</li>);

  // console.log(allCustomBlocks)
  // console.log( blocks[0].acf.code )


  return (
    <Layout
      bodyClass={`post-template-default single single-post postid-${databaseId} single-format-standard wp-embed-responsive singular has-post-thumbnail has-single-pagination showing-comments footer-top-visible customize-support`}
    >
      
      <Seo title={title} description={excerpt} />

      <TopPage topPageData={ACFPostFields}  />
      
      <article
        className={`post-${databaseId} mt-5 pt-5 post type-post status-publish format-standard has-post-thumbnail hentry category-uncategorized`}
        id={`post-${databaseId}`}
      >

        {/* <header>
              <h1
                  className="entry-title"
                  dangerouslySetInnerHTML={{ __html: title }}
                />
                <div
                  className="intro-text section-inner max-percentage small"
                  dangerouslySetInnerHTML={{ __html: excerpt }}
                />
        </header> */}

        <FeaturedMedia image={featuredImage} />


        <ReadMore />

        <div className="container">
          <BlockBuilder blocks={blocks} title={title} />
        <div>
        
         {allBlocks.map(function(item, i){

          // Will have to loop through each block type
           if ( item.__typename !== "WpAcfCodeBlock" ) {
              return (

                  <div key={i}
                  className="entry-content"
                  dangerouslySetInnerHTML={{ __html: item.originalContent }}
                />
               
              )
           } else if ( item.__typename == "WpAcfCodeBlock" ) {

            return (
              <li key={i}>
                ACF!
                {item.__typename}
                {item.acf.code}
               
                {/* {allBlocks.values({ acf }).code} */}
                
                {/* {allBlocks.map( acf => <div key={acf.code} className="channel">{code}</div> ) } */}

              

              </li>
            )
          } 

          })}
  


        
        </div>
        </div>

        <div className="post-inner thin mb-5">
        { /* will need to replace this */ }
          <div
            className="entry-content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>


        <footer className="entry-footer has-text-align-center header-footer-group pt-0">
          <div className="entry-footer-inner m-0 w-100 pb-3 pt-3">

            <div className="container-fluid m-0">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12  post-content-meta">
                
                <div className="meta-wrap d-flex align-items-center justify-content-center flex-wrap">
                  <p tabIndex="3">article published on</p>
                  <PostMeta title={title} author={author} date={date} tabIndex="3" />
                  <p tabIndex="3">in the following categories</p>
                  <PostCategories categories={categories} />
                </div>
                
              </div>
            </div>
            </div>
          </div>
        </footer>

        <section className="post-navigation">
          
          <ContentTypePagination
            previousPage={previousPage}
            nextPage={nextPage}
            contentType={"Post"}
          />
         
        </section>
      </article>

      

    </Layout>
  )
}



export const query = graphql`
  query post($id: String!, $nextPage: String, $previousPage: String) {
    page: wpPost(id: { eq: $id }) {
      ...PostContent

      blocks {
        originalContent

        ... on WpAcfPageBannerBlock {
          dynamicContent
          originalContent
          acf {
            about_face {
              remoteFile {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
            }
            background_color
            page_title
          }
        }

        ... on WpAcfLayoutBuilderBlock {
          dynamicContent
          originalContent
          acf {
            row {
              columns {
                text_and_image
                text
                video
                image {
                  remoteFile {
                    childImageSharp {
                      fluid {
                        src
                      }
                    }
                  }
                }
              }
            }
          }
        }

        ... on WpAcfFullwidthImageBlock {
          dynamicContent
          originalContent
          acf {
            video
            image {
              remoteFile {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
            }
            caption
          }
        }

        ... on WpAcfImmersiveScrollBlock {
          dynamicContent
          originalContent
          acf {
            text_align
            sub_title
            solid_color
            paragraph
            gradient
            title
            video
            image {
              remoteFile {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
            }
          }
        }

        ... on WpAcfGenericSliderBlock {
          dynamicContent
          originalContent
          acf {
            slide {
              slide_settings {
                
                background_credits
                credit_link
                credit_text
                gradient_background
                image_background {
                  remoteFile {
                    childImageSharp {
                      fluid {
                        src
                      }
                    }
                  }
                }
                image_or_video
                solid_color_background
                solid_or_gradient
                video_url
              }
            }
          }
        }

        ... on WpAcfIntroBlock {
          dynamicContent
          originalContent
          acf {
            sub_title
            title
          }
        }

        ... on WpAcfIconListBlock {
          dynamicContent
          originalContent
          acf {
            add_column {
              image {
                remoteFile {
                  childImageSharp {
                    fluid {
                      src
                    }
                  }
                }
              }
              svg {
                localFile {
                  publicURL
                  extension
                  publicURL
                }
              }
              svg_or_image
              title
            }
          }
        }

        ... on WpAcfLargeTextBlock {
          dynamicContent
          originalContent
          acf {
            gradient_or_solid
            solid_text_color
            text
          }
        }

        ... on WpAcfTabsBlock {
          dynamicContent
          originalContent
          acf {
            tab {
              tab_content
              tab_title
              tab_title_icon {
                remoteFile {
                  childImageSharp {
                    fluid(maxWidth: 75, quality: 92) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }

        ... on WpAcfPortfolioHeroBannerBlock {
          dynamicContent
          originalContent
          acf {
            project_color
            project_secondary_color
            project_slides {
              add_background_credits
              background_credit_text
              image_or_video
              projectimage {
                remoteFile {
                  childImageSharp {
                    fluid {
                      src
                    }
                  }
                }
              }
              projectvideo
            }
            project_subtitle
          }
        }

        ... on WpAcfPortfolioOverviewBlock {
          dynamicContent
          originalContent
          acf {
            created_at {
              target
              title
              url
            }
            project_link {
              target
              title
              url
            }
            services
            year_created
          }
        }

        ... on WpAcfMediumTextBlock {
          dynamicContent
          originalContent
          acf {
            content
          }
        }

        ... on WpAcfTwoImageOffsetBlock {
          dynamicContent
          originalContent
          acf {
            background_color
            first_image {
              image {
                remoteFile {
                  childImageSharp {
                    fluid {
                      src
                    }
                  }
                }
              }
              caption
            }
            second_image {
              image {
                remoteFile {
                  childImageSharp {
                    fluid {
                      src
                    }
                  }
                }
              }
              caption
            }
          }
        }

        ... on WpAcfImageGridBlock {
          dynamicContent
          originalContent
          acf {
            grid_type
            images {
              image_or_video
              video
              image {
                remoteFile {
                  childImageSharp {
                    fluid {
                      src
                    }
                  }
                }
              }
            }
          }
        }
        
        ... on WpAcfCodeBlock {
          dynamicContent
          originalContent
          acf {
            code
          }
        }

        ... on WpAcfFooterContactBlock {
          dynamicContent
          originalContent
          acf {
            body_text
            email
            subject_message
          }
        }
        
        ... on WpAcfContactFormBlock {
          dynamicContent
          originalContent
          acf {
            email_address
          }
        }

        ... on WpAcfServiceIntroBlock {
          dynamicContent
          originalContent
          acf {
            background_title
            description
            main_title
            service_color
            service_icon {
              remoteFile {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
            }
          }
        }
        
        ... on WpAcfServiceGridBlock {
          dynamicContent
          originalContent
          acf {
            service_color
            service_item {
              description
              icon
              lottie_slug
              title
            }
          }
        }

        ... on WpAcfPortfolioSliderBlock {
          dynamicContent
          originalContent
          acf {
            slide {
              portfolio_item {
                ACFAllPostFields {
                  mainColor
                  mainImage {
                    remoteFile {
                      childImageSharp {
                        fluid {
                          src
                        }
                      }
                    }
                  }
                  secondaryColor
                }
                ACFPortfolioFields {
                  fieldGroupName
                  gradientBackground
                  homeGridLayoutSelection
                 
                  portfolioItemSubHeader
                  portfolioLottieSvg
                 
                  portfolioPng {
                    remoteFile {
                      childImageSharp {
                        fluid {
                          src
                        }
                      }
                    }
                  }
                  portfolioSvg {
                    remoteFile {
                      childImageSharp {
                        fluid {
                          src
                        }
                      }
                    }
                  }
                }
                featuredImage {
                  node {
                    remoteFile {
                      childImageSharp {
                        fluid {
                          src
                        }
                      }
                    }
                    
                  }
                }
                slug
                title
              }
            }
          }
        }

        ... on WpAcfPortfolioGridBlock {
          dynamicContent
          originalContent
          acf {
            grid_item {
              portfolio_item {
                categories {
                  nodes {
                    slug
                    uri
                    name
                  }
                }
                ACFAllPostFields {
                  mainColor
                  mainImage {
                    remoteFile {
                      childImageSharp {
                        fluid {
                          src
                        }
                      }
                    }
                  }
                  secondaryColor
                }
                ACFPortfolioFields {
                  fieldGroupName
                  gradientBackground
                  homeGridLayoutSelection
                 
                  portfolioItemSubHeader
                  portfolioLottieSvg
                  portfolioPng {
                    remoteFile {
                      childImageSharp {
                        fluid {
                          src
                        }
                      }
                    }
                  }
                  portfolioSvg {
                    remoteFile {
                      childImageSharp {
                        fluid {
                          src
                        }
                      }
                    }
                  }
                  
                }
                slug
                uri
                title
              }
            }
          }
        }



      }

      ACFPostFields {
        postSubTitle
        titleLead
        topBanner {
          articleIcon
          articleSubtitle
          articleTitle {
            fieldGroupName
            firstRow
            secondRow
            thirdRow
          }
          backgroundCredits
          creditLink
          creditText
          fieldGroupName
          gradientBackground
          imageBackground {
            remoteFile {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
          }
          imageOrVideo
          lineIconCode
          lottieFile
          solidColorBackground
          solidOrGradient
          svgCode
          svgUpload {
            localFile {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
            remoteFile {
              publicURL
            }
          }
          videoUrl
        }
      }
      ACFAllPostFields {
        mainColor
        secondaryColor
        mainImage {
          remoteFile {
            childImageSharp {
              fluid {
                src
              }
            }
          }
        }
      }
     
      
     
    }
    nextPage: wpPost(id: { eq: $nextPage }) {
      ...PostContent
      title
      uri
      ACFPostFields {
        postSubTitle
        titleLead
        topBanner {
          articleIcon
          articleSubtitle
          articleTitle {
            fieldGroupName
            firstRow
            secondRow
            thirdRow
          }
          backgroundCredits
          creditLink
          creditText
          fieldGroupName
          gradientBackground
          imageBackground {
            remoteFile {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
          }
          imageOrVideo
          lineIconCode
          lottieFile
          solidColorBackground
          solidOrGradient
          svgCode
          svgUpload {
            localFile {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
            remoteFile {
              publicURL
            }
          }
          videoUrl
        }
      }
    }
    previousPage: wpPost(id: { eq: $previousPage }) {
      ...PostContent
      title
      uri
      ACFPostFields {
        postSubTitle
        titleLead
        topBanner {
          articleIcon
          articleSubtitle
          articleTitle {
            fieldGroupName
            firstRow
            secondRow
            thirdRow
          }
          backgroundCredits
          creditLink
          creditText
          fieldGroupName
          gradientBackground
          imageBackground {
            remoteFile {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
          }
          imageOrVideo
          lineIconCode
          lottieFile
          solidColorBackground
          solidOrGradient
          svgCode
          svgUpload {
            localFile {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
          }
          videoUrl
        }
      }
      ACFAllPostFields {
        mainColor
        secondaryColor
        mainImage {
          remoteFile {
            childImageSharp {
              fluid {
                src
              }
            }
          }
        }
      }
    }
  }
`
