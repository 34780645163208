import React from 'react'

const Credits = ({creditData}) => {
    if ( creditData.backgroundCredits == "Yes" ) {
        return (
            <div class="img-credits">
                <a href={creditData.creditLink}>{creditData.creditText}</a>
            </div>
          )
    } else {
        return (
            <div data-credits="no credits"></div>
        )
    }
}

export default Credits
